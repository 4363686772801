<template>
    <div>
        <div>
        <el-table  :data="mlData" 
              border                            
              size="small"      
              :height="300" 
              :row-key="getRowKey"
              highlight-current-row
              ref="reftab"
              style="overflow:auto;margin-top:1px;"
              :row-class-name="tableRowClassName"
              @row-click="rowClick" > 
              <el-table-column type="index" width="60" label="序号" fixed></el-table-column>
            <span v-for="(item ) in tabCol" :key="item.key" > 
                <el-table-column  v-if="item.thide==='N' "
                    :prop="item.key"
                    :label="item.title"
                    show-overflow-tooltip
                    :align="item.align"                                  
                    sortable
                    :min-width="item.width">
                </el-table-column> 
            </span> 
        </el-table>  
        </div>            
        <div style="background:white; margin-top:15px">
            <Form  :model="mailRow"  :label-width="150"  label-position="right" inline > 
                <span v-for="(item,index) in tabCol" :key="index" >
                    <FormItem :label="item.title "  class="item-width" v-if="item.fhide==='N'" :prop="item.key">               
                        <el-input rows="3" v-if="item.key=='NOTE'||item.key=='ENOTE'" type="textarea"   v-model="mailRow[item.key]" style="width:100%"></el-input>
                        <el-input  v-else type="text" :disabled="item.disabled==='Y'?true:false "  v-model="mailRow[item.key]" style="width:100%"></el-input>
                    </FormItem>
                </span>
            </Form>
           
            <el-button type="primary" size="small" icon="iconfont icon-baocun"  @click="saveRec" style="margin-right:10px;">保存</el-button>         
            
        </div>
 
        <!-- 提示框 -->
        <el-dialog :visible.sync="promptWin" width="450px" append-to-body>
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">提示</span>
            </div>
            <div   >{{prompt}} </div>
            <span slot="footer"  :style="{'height':'40px' }">
                <el-button size="small" type="primary" @click="promptWin = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
 
import {getBaseData,getTabColOrData} from '../../api/user' 
export default ({
    name:'mail_content',
    data () {
        return {
            rowIndex:'',
            tabRow:{},
            mailRow:{},
            tabCol:[],
            mlData:[],
            v_username :this.$cookies.get('v_username' ), //用户名称 
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            v_url:this.$store.state.queryUrl , //api请求路径  
            promptWin:false,
            prompt:'',
            v_idseq:'',
        }
    },
    created () {
        getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','108','fty','HEAD','','',' order by sortby,lstseq' ).then((res) => {
            for (let k=0; k<res.data.length; k++){
                this.tabCol.push({ //向数组的开头 添加序列号
                    title: res.data[k].title,
                    key: res.data[k].key,
                    align: res.data[k].align,
                    width: res.data[k].width,  
                    thide: res.data[k].THIDE,  
                    fhide: res.data[k].FHIDE,
                    disabled: res.data[k].DISABLED,  
                    edcss: res.data[k].EDCSS,                 
                })
            }
        }) 
    },
    mounted () {
        this.getMailList()  
    
    },
    methods: {
        tableRowClassName({ row, rowIndex }) {
            row.index = rowIndex;
        },
        //邮件内容明细 
        getMailList(){
            let vm =this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'V_CONTENT_SET','','','','','','', '' ).then((res) => { 
                this.mlData =res.data 
                if (res.data.length>0) {
                    this.$nextTick(() => { //有固定列时 查询后重新布局
                        this.$refs['reftab'].doLayout()
                        this.$refs['reftab'].setCurrentRow(res.data[0])
                        this.v_idseq =res.data[0]['IDSEQ']
                        this.tabRow =res.data[0]
                        this.mailRow =JSON.parse(JSON.stringify(res.data[0]))
                    })
                }
            })
        },
        rowClick(row ){
            console.log(row.index)
            this.rowIndex =row.index
            this.v_idseq =row['IDSEQ']
            this.mailRow =JSON.parse(JSON.stringify(row))
            this.tabRow =JSON.parse(JSON.stringify(row))
            //console.log(JSON.stringify(this.tabRow))
        },
        getRowKey(row){
            return row.IDSEQ
        },
        //保存记录并发送邮件
        saveRec(){
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'sysprivs/formOneSave?username='+this.v_username +'&password='+this.v_password ,
                data: {p_json:JSON.stringify(Object.assign(this.mailRow,{IDSEQ:this.v_idseq})), p_table:'CONTENT_SET'},
            }).then(res=>{  
                console.log(JSON.stringify(this.mailRow))    
                if(res.data.result =='OK')  {          
 
                     this.mlData.splice(this.rowIndex,1,Object.assign(this.tabRow,this.mailRow ))
                     this.prompt ='数据已保存！'
                     this.promptWin=true
                }else{
                    this.prompt =res.data.result
                    this.promptWin=true
                }        
            }) 
        }
 
    }
})
</script>
<style scoped lang="less">
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
.main_back{
 
    width:100%;
    background: white;
}
.main_back .hor{
    text-align:center;
    background: white;
    font-weight: 700;
    font-size: 14px;
}
.item-width{
    line-height:40px;
    background:rgb(218, 221, 221);
    width:100%;
}
</style>
<style lang="less">
 
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    //.el-dialog__headerbtn i { background:white;font-size: 20px;  }
    
</style>
